import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FileType } from "@app/account/account.models";
import { CartType, IAddToCartModel, IBuilderPromo, ICartItemModel, IExportFilter, IOrderLifecycleEvent, IOrderTotalsModel, ISavedCart, IShippingMethod } from "@app/checkout/checkout.models";
import { IKeycapPreview, KeycapPreview } from "@app/products/products.models";
import { IInvoicePaymentModel } from "@app/shared/payment.models";
import { IGenericErrorListResponse, IGenericResponse } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { CartUpdateSuccess, ClearCartSuccess, UpdateModifyingCart } from "@app/store/cart";
import { ClearCustomerSummaries } from "@app/store/customer-summary";
import { ErrorToast, SuccessToast, WarningToast } from "@app/store/toast/toast.actions";
import { Store } from "@ngrx/store";
import { format as dateFnsFormat } from "date-fns";
import { saveAs } from "file-saver";
import { tap } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class CartService {
	constructor(readonly http: HttpClient, readonly store: Store<IAppState>, readonly router: Router) { }

	editCartItem(item: IAddToCartModel, originalOrderDetailId: number, originalAddOnItem?: IAddToCartModel, lifeCycleEvent?: IOrderLifecycleEvent) {
		lifeCycleEvent = Object.assign({
			page: window.location.pathname,
			action: "AddToCart",
			actionSource: "JS-AddToCart",
			itemSku: item.sku,
			itemQuantity: item.quantity,
		}, lifeCycleEvent || {});
		const data = { item, originalOrderDetailId, originalAddOnItem, lifeCycleEvent };

		this.store.dispatch(new UpdateModifyingCart({ orderId: item.orderId, modifying: true }));

		return this.http.post<IGenericCartResponse>("api/cart/editCartItem", data);
	}

	addToCart(item: IAddToCartModel, isInCart = false, lifeCycleEvent?: IOrderLifecycleEvent) {
		lifeCycleEvent = Object.assign({
			page: window.location.pathname,
			action: "AddToCart",
			actionSource: "JS-AddToCart",
			itemSku: item.sku,
			itemQuantity: item.quantity,
		}, lifeCycleEvent || {});
		const data = { item, lifeCycleEvent, isInCart };

		this.store.dispatch(new UpdateModifyingCart({ orderId: item.orderId, modifying: true }));

		return this.http.post<IGenericCartResponse>("api/cart/addItem", data);
	}

	applyPromo(promoCode: string, orderId: number, lifeCycleEvent?: IOrderLifecycleEvent) {
		lifeCycleEvent = Object.assign({
			page: window.location.pathname,
			action: "ApplyPromo",
			actionSource: "JS-AddToCart",
			itemSku: promoCode,
			itemQuantity: 1,
		}, lifeCycleEvent || {});
		this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: true }));

		return this.http.post<IGenericCartResponse>("api/cart/applyPromo", { promoCode, orderId, lifeCycleEvent });
	}

	// todo: Update service to take lifeCycleEvent
	updateQuantity(item: ICartItemModel, orderId: number, isInCart = false, lifeCycleEvent?: IOrderLifecycleEvent) {
		lifeCycleEvent = Object.assign({
			page: window.location.pathname,
			action: "ChangeQuantity",
			actionSource: "JS-Cart",
			itemSku: item.sku,
			itemQuantity: item.quantity,
		}, lifeCycleEvent || {});
		this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: true }));

		return this.http.post<IGenericCartResponse>("api/cart/UpdateQuantity", { orderDetailId: item.orderDetailId, quantity: item.quantity, orderId, isInCart }).subscribe(result => {
			this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: false }));
			if (result.success) {
				this.store.dispatch(new CartUpdateSuccess(result.totals));
				if (result.warningMessage) {
					this.store.dispatch(new WarningToast({ message: result.warningMessage }));
				}
			} else {
				this.store.dispatch(new WarningToast({ message: "There was a problem updating the quantity." }));
			}
		});
	}

	// todo: Update service to take lifeCycleEvent
	removeItem(item: ICartItemModel, orderId: number, isInCart = false, lifeCycleEvent?: IOrderLifecycleEvent) {
		lifeCycleEvent = Object.assign({
			page: window.location.pathname,
			action: "RemoveFromCart",
			actionSource: "JS-Cart",
			itemSku: item.sku,
			itemQuantity: item.quantity,
		}, lifeCycleEvent || {});
		this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: true }));
		this.http.post<IGenericCartResponse>("api/cart/RemoveItem", { orderDetailId: item.orderDetailId, orderId, isInCart }).subscribe(result => {
			this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: false }));
			if (result.success) {
				this.store.dispatch(new CartUpdateSuccess(result.totals));
				if (result.warningMessage) {
					this.store.dispatch(new WarningToast({ message: result.warningMessage }));
				}
				this.store.dispatch(new SuccessToast({ message: `Removed ${item.name} (${item.sku}) from cart.` }));
			} else {
				this.store.dispatch(new WarningToast({ message: "There was a problem removing the item from your cart." }));
			}
		});
	}

	removePromo(promoCode: string, orderId: number, lifeCycleEvent?: IOrderLifecycleEvent) {
		lifeCycleEvent = Object.assign({
			page: window.location.pathname,
			action: "RemoveFromCart",
			actionSource: "JS-Cart",
			itemSku: promoCode,
		}, lifeCycleEvent || {});
		this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: true }));

		return this.http.post<IGenericCartResponse>("api/cart/RemovePromo", { promoCode, orderId, lifeCycleEvent }).pipe(tap(result => {
			if (result.success) {
				this.store.dispatch(new CartUpdateSuccess(result.totals));
				if (result.warningMessage) {
					this.store.dispatch(new WarningToast({ message: result.warningMessage }));
				} else if (result.errorMessage) {
					this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: false }));
					this.store.dispatch(new ErrorToast({ message: result.errorMessage }));
				}
			}
		}));
	}

	clearCart(orderId: number) {
		this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: true }));

		return this.http.post<boolean>("api/cart/ClearCart", orderId).pipe(tap(result => {
			this.store.dispatch(new UpdateModifyingCart({ orderId, modifying: false }));
			if (result) {
				this.store.dispatch(new ClearCartSuccess({ orderId }));
				this.addOrderLifeCycleEvent({ page: window.location.pathname, action: "ClearCart", actionSource: "Cart" });
			} else {
				this.store.dispatch(new ErrorToast({ message: "There was a problem clearing the cart." }));
			}
		}));
	}

	getShippingMethod(id: number) {
		return this.http.get<IShippingMethod>("api/checkout/getShippingMethod", { params: { id: id.toString() } });
	}
	getCheckc4ctkitbyAccount(AccountNo: string, kitskus: string) {
		return this.http.get<IGenericCartResponse>("api/Cart/CheckC4ctkitByaccount", { params: { Accountno: AccountNo, skus: kitskus } });
	}

	chargePaymentForInvoices(model: IInvoicePaymentModel) {
		return this.http.post<IGenericErrorListResponse>("api/order/ChargePaymentForInvoices", model);
	}

	getCvcMaxLength(cardType: string) {
		switch (cardType) {
			case "AMEX": return 4;
			default: return 3;
		}
	}

	addOrderLifeCycleEvent(event: IOrderLifecycleEvent) {
		event.page = event.page || this.router.url;
		this.http.post<boolean>("api/Order/AddOrderLifeCycleEvent", event);
	}

	goToCheckout(actionSource: string): void {
		this.addOrderLifeCycleEvent({ action: "GoToCart", actionSource });
		this.router.navigateByUrl("checkout");
	}

	getKeycapsInCart(orderId: number) {
		return this.http.get<IKeycapPreview[]>("api/cart/GetKeycapsInCart", { params: { orderId: orderId.toString() } });
	}

	updateKeycapItem(item: KeycapPreview) {
		return this.http.post<IGenericCartResponse>("api/cart/UpdateKeycapItem", this._prepKeycapData(item)).pipe(tap(result => {
			if (result.success) {
				const message = item.quantity > 0 ? "Keycap updated" : "Keycap removed";
				this.store.dispatch(new SuccessToast({ message }));
				this.store.dispatch(new CartUpdateSuccess(result.totals));
			}
		}));
	}

	private _prepKeycapData(item: KeycapPreview) {
		const iconIndex = item.position === "left" ? 0 : 1;
		const textIndex = item.position === "left" ? 1 : 0;

		if (item.isGen3()) {
			item.richTopLine = [];
			item.richTopLine[iconIndex] = { isIcon: true, value: item.icon.toString() };
			item.richTopLine[textIndex] = { isIcon: false, value: item.topLine };
		}

		return item;
	}

	getSavedCarts() {
		return this.http.get<ISavedCart[]>("api/cart/GetSavedCarts");
	}

	loadSavedCart(savedCartId: number, orderId: number) {
		return this.http.post<IGenericCartResponse>("api/cart/LoadSavedCart", { savedCartId, orderId }).pipe(tap(result => {
			if (result.success) {
				this.store.dispatch(new CartUpdateSuccess(result.totals));
			} else {
				this.store.dispatch(new WarningToast({ message: "There was a problem loading the saved cart." }));
			}
		}));
	}

	getConsumerCart(accountName: string) {
		return this.http.get<IOrderTotalsModel>("api/cart/getConsumerCart", { params: { accountName } });
	}

	saveCart(name: string, orderId: number, cartType?: CartType) {
		return this.http.post<number>("api/cart/SaveCart", { name, orderId, cartType });
	}

	updateSavedCart(savedCart: ISavedCart) {
		return this.http.post<boolean>("api/cart/UpdateSavedCart", savedCart);
	}

	deleteSavedCart(savedCart: ISavedCart) {
		return this.http.delete<boolean>("api/cart/DeleteSavedCart", { params: { orderId: savedCart.id.toString() } }).pipe(tap(result => {
			if (result) {
				if (savedCart.cartType === CartType.Upgrade) {
					this.store.dispatch(new ClearCustomerSummaries());
				}
				this.store.dispatch(new SuccessToast({ message: `Deleted ${savedCart.name}.` }));
			} else {
				this.store.dispatch(new WarningToast({ message: `There was a problem deleting ${savedCart.name}.` }));
			}
		}));
	}

	getRecommendedProductSkus(orderId: number) {
		return this.http.get<string[]>("api/cart/getRecommendedProductSkus", { params: { orderId: orderId.toString() } });
	}

	getAppliedBuilderPromos(orderId: number) {
		return this.http.get<IBuilderPromo[]>("api/cart/GetAppliedBuilderPromos", { params: { orderId: orderId.toString() } });
	}

	updateReferences(project: string, community: string, customerReference: string, orderId: number) {
		return this.http.post<boolean>("api/cart/UpdateReferences", { project, community, customerReference, orderId });
	}

	signPartnerContract(contractId: number) {
		return this.http.post<boolean>("api/checkout/SignPartnerContract", contractId);
	}

	uploadCsvCart(files: FileList, orderId: number) {
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append("file", files[i]);
		}
		formData.append("orderId", orderId.toString());

		return this.http.post<IGenericCartResponse>("api/cart/UploadCsvOrder", formData);
	}

	exportSalesQuotation(savedCartId: number, exportFilter: IExportFilter, projectName: string, type: FileType, complete: () => void) {
		this.http.get("api/Account/ExportSalesQuotation",
			{
				params: {
					savedCartId: savedCartId.toString(),
					includeMsrpPricing: exportFilter.includeMsrpPricing.toString(),
					includeDealerPricing: exportFilter.includeDealerPricing.toString(),
					includeControl4Logo: exportFilter.includeControl4Logo.toString(),
					fileType: type.toString(),
				},
				responseType: "blob",
			}).subscribe(result => {
				const extension = type === FileType.Xls ? ".xlsx" : ".csv";
				saveAs(result, `${projectName}-${dateFnsFormat(new Date(), "YYYY-MM-DD")}${extension}`);
				complete();
			});
	}
}

export interface IGenericCartResponse extends IGenericResponse {
	totals: IOrderTotalsModel;
}